import "./css/posts.css";
import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Importación correcta de useTranslation
import Header from './components/header';
import Hero from "./components/hero";

import Contact from './components/contact';
import Footer from './components/footer';


const PostDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams(); // Para obtener el id del post desde la URL

  // Datos de los posts
  const postDetails = [
    {
      id: 1,
      title: t("Opinión positiva"),
      image: "https://e00-expansion.uecdn.es/assets/multimedia/imagenes/2015/05/06/14309087496539.jpg",
      content: "More than 4,000 hours went into redesigning, redeveloping, and migrating the Presidential Library and Museum of Gerald Ford from Drupal 7 into Drupal 10. The top contributors at Agileana included Ramiro, Lucas, Camilo, Mike, Sean, Diana, and Krista. The Gerald R. Ford Presidential Museum, and its sister institution, the Gerald R. Ford Presidential Library, are part of the Presidential libraries system administered by the National Archives and Records Administration, a federal agency.  The Library is located in Ann Arbor and the Museum is located in downtown Grand Rapids, Michigan.",
    },
    {
      id: 2,
      title: t("Opinión positiva"),
      image: "https://e00-expansion.uecdn.es/assets/multimedia/imagenes/2015/05/06/14309087496539.jpg",
      content: "Aquí va la explicación más larga del post 2...",
    },
    {
      id: 3,
      title: t("Opinión positiva sobre el servicio contratado"),
      content: "Aquí va la explicación más larga del post 3...",
    },
  ];

  // Para encontrar el post segun el id
  const post = postDetails.find((post) => post.id === parseInt(id));

  // Si el post no existe, mensaje de error
  if (!post) {
    return <div>Post no encontrado</div>;
  }

  return (
    <div className="App">
      
      <Header />
      <Hero />

      <div className="post-detail-container" >
        <div className="post-detail">
          <h1>{post.title}</h1>

          <img className="img-post"
            src={post.image}
            alt={post.title}
          />

          <p>{post.content}</p>
        </div>
      </div>
      <Contact />
      <Footer />
    </div>
  );
};

export default PostDetail;

