import "../css/posts.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Posts = () => {
  const { t } = useTranslation();

  // Datos de los posts
  const Posts = [
    {
      id: 1,
      title: t("news_wwe_title"),
      image: require("../assets/img/logos/WWE-logo.jpg"),
      description: t("news_wwe_description"),
    },
    {
      id: 2,
      title: t("news_unesco_title"),
      image: require("../assets/img/logos/unesco-logo.png"),
      description: t("news_unesco_description"),
    },
    {
      id: 3,
      title: t("news_asu_title"),
      image: require("../assets/img/logos/asu.png"),
      description: t("news_asu_description"),
    },
  ];

  return (
    <section className="post section" id="posts">
      <div id="featured-post" className="py-5">
        <div className="container">
          <div class="row">
            <div class="col">
              <h2
                class="section-heading text-center mb-5 aos-init aos-animate"
                data-aos="fade-down"
                data-aos-delay="100"
              >
                {t("our_work_in_action")}
              </h2>
            </div>
          </div>
          <div className="row">
            {Posts.map((post) => (
              <article key={post.id} className="col-12 col-md-4 my-3 blog-card">
                <figure>
                 {/* <Link to={`/post/${post.id}`}>*/}
                    <img
                      src={post.image}
                      alt={post.title}
                      className="blog-image"
                    />
                  {/*</Link>*/}
                </figure>
                <h3 className="h5 blog-title">
                {post.title} {/*<Link to={`/post/${post.id}`}>{post.title}</Link>#*/}
                </h3>
                <p>{post.description}</p>
              </article>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Posts;
